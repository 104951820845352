<!--
 * @Description: 
 * @version: 
 * @Author: zhangzhiqiang
 * @Date: 2021-09-10 13:32:41
 * @LastEditors: zmj
 * @LastEditTime: 2022-07-11 14:53:11
-->
<template>
  <div class="home_cp_tit">
    <swiper :interval="interval" class="home_pictrue">
      <swiper-item v-for="(item, index) in navData" :key="index">
        <img :src="pictureUrl + item.url" @click="imgList(item.linkUrl)" />
      </swiper-item>
    </swiper>
    <div class="clear"></div>
    <div class="picture-nav-menu">
      <ul class="w picture-nav">
        <li
          v-for="(item, index) in navData"
          :key="index"
          @click="tigger(index, item.linkUrl)"
        >
          {{ item.bt }}
        </li>
      </ul>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import { salesVolume, advertPicture } from "./model";
import { Swiper, SwiperItem } from "../../components/carousel/index";
import { IMGURL } from "@/utils/constant";
export default {
  components: {
    Swiper,
    SwiperItem,
  },
  data() {
    return {
      interval: 8000,
      navData: "",
      pictureUrl: "",
      Url: "",
      shareLink: "",
      currentIndex: 0,
    };
  },

  created() {
    this.shareLink = window.location.href;
    this.pictureUrl = IMGURL;
    advertPicture({ lx: "012" }).then((res) => {
      this.navData = res;
    });
  },

  methods: {
    tigger(index, url) {
      let routeData = this.$router.resolve({
        path: url,
      });

      if (
        routeData.href.indexOf("http") != -1 ||
        routeData.href.indexOf("https") != -1
      ) {
        let routeList = routeData.href.replace("#/", "");
        window.open(routeList, "_blank");
      } else {
        window.open(routeData.href, "_blank");
      }
    },

    imgList(url) {
      let routeData = this.$router.resolve({
        path: url,
      });

      if (
        routeData.href.indexOf("http") != -1 ||
        routeData.href.indexOf("https") != -1
      ) {
        let routeList = routeData.href.replace("#/", "");
        window.open(routeList, "_blank");
      } else {
        window.open(routeData.href, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.swiper-item img {
  height: 120px;
}
</style>