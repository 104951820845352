<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: zmj
 * @LastEditTime: 2022-07-01 16:23:46
-->
<template>
  <div class="learning-center">
    <div class="">
      <!-- 图标按钮 -->
      <div class="card-nav">
        <div class="w nav-menu" v-if="queryAdvList.length > 0">
          <div
            class="nav-menu_1"
            v-for="(item, index) in queryAdvList"
            :key="index"
            @click="goNewProduct(item.linkUrl)"
          >
            <div class="nav-menu_1_img">
              <img :src="pictrueUrl + item.url" alt="" />
            </div>
            <div class="clear"></div>
            <div class="nav-menu_1_title">
              <span>{{ item.bt }}</span>
              <!-- <p>{{ item.zszd }}</p> -->
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <!-- 卡片视图 -->
      <div class="w card-view">
        <div
          class="card-view_1"
          v-for="(item, index) in cardList"
          :key="index"
          @click="goPage(item)"
        >
          <h2>{{ item.cateKeys }}</h2>
          <div class="card-view_1_img">
            <img :src="pictrueUrl + item.icon" alt="" />
          </div>
          <div class="content">
            <span>{{ item.cateName }}</span>
            <div class="card-dec">
              {{ item.cateDesc }}
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { productClassify, indexCateList, advertPicture } from "./model";

import { IMGURL } from "@/utils/constant";
export default {
  data() {
    return {
      IMGURL,
      // productData:[],
      cardList: "",
      pictrueUrl: "",
      queryAdvList: "",
      productInfoList: [],
      proList: [],
      attrList: [],
      params: [],
    };
  },
  created() {
    this.pictrueUrl = IMGURL;
    indexCateList().then((res) => {
      this.cardList = res;
    });
    advertPicture({ lx: "013" }).then((res) => {
      this.queryAdvList = res;
    });
  },
  methods: {
    goNewProduct(url) {
      this.$router.push({ path: url });
    },
    goPage(item) {
      if (item.productInfo === undefined) {
        return;
      } else {
        item.productInfo.forEach((res) => {
          let treeObject = {
            attrCode: res.id,
            attrValue: res.label,
            fid: res.fid,
          };
          this.attrList.push(treeObject);
        });
      }
      console.log(item,"=====");
      console.log(this.attrList,"======");
      if (item.cc == 2) {
        this.$router.push({
          path: "/anti?id=54",
          query: { params: JSON.stringify(this.attrList) },
        });
      } else {
        this.$router.push({
          path: "/anti?id=94",
          query: { params: JSON.stringify(this.attrList) },
        });
      }
    },
  },
};
</script>

