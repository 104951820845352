<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-06 18:21:19
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-11 10:40:37
-->
<template>
  <div class="home">
   <product/>
   <learning-center/>
   <!-- <technical-service/> -->
  </div>
</template>

<script>
import Product from '@/views/index/Product.vue';
import LearningCenter from '@/views/index/LearningCenter.vue';
// import TechnicalService from '@/views/index/TechnicalService.vue';
export default {
  name: 'Index',
   data() {
    return {
    }
  },
  components: {
    Product,
    LearningCenter,
    // TechnicalService
  },
}
</script>
<style scoped>
  .shaixuan_rt{
    height: .25rem;
    width: 100% !important;
  }
   .shaixuan_rt input {
     height: 100% !important;
   }
</style>